<template>
<div class="accordion accordion-flush">
  <div class="accordion-item" v-if="src">
    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Source
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">
        <ul><a :href=src target="_blank">{{src}}</a></ul>
      </div>
    </div>
  </div>
  <div class="accordion-item" v-if="notes">
    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
        Methodological Notes
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
      <div class="accordion-body">
        <ul><p>{{notes}}</p></ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: "Accordion",
    props: ["src","notes"],
    setup(){

    }

}
</script>

<style scoped>
    .accordion-body{
        text-align:left;
        font-size:14px;
    }
    ul {
        list-style-type:none;
    }
</style>