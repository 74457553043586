<template>
<div class="container">
  <BaseChart :chartOptions="chartOptions" :error="error" />
  <div class="row mt-5" v-if="data.length > 0">
  <div class="sliderwrapper">
    <Slider v-model="sliderOptions.value" 
      :format="sliderOptions.format" 
      :min = "sliderOptions.min"
      :max = "sliderOptions.max"
      :merge ="sliderOptions.merge"
      :options="sliderOptions.options"
      @update="sliderUpdate"  
      class = "mt-5" />
    </div>
    </div>
<div class="row mt-5" v-if="data.length > 0"> 
  
  <DataTable :data="to_pass" :titles="titles" />
</div>

<div class="row mt-5"> 
  <Accordion :src="src" :notes="notes" class = "mt-5"/>
</div>

  
  </div>
</template>

<script>
import getData from '../composables/getDataParams'
import BaseChart from '@/components/BaseChart.vue'
import DataTable from '@/components/DataTable2.vue'
import {ref, watchEffect} from 'vue'
import Slider from '@vueform/slider'
import Accordion from '@/components/Accordion.vue'

export default {
    name: 'MortgageOriginationView',
    components: {BaseChart, DataTable, Slider,Accordion},
    setup(){

        const {data, error, load} = getData()
        load('mortgage-origination')

        let month_names = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
        let originaldata = []

        let months = []

        const to_pass = ref([])




        const titles = ref(['EUR million'])

        const sliderOptions = ref({
          value: [0,100],
          format: function (value) {
                    if(data.value.length > 0){
                      //console.log(originaldata)
                      try{
                        return month_names[Number(originaldata[value].month.slice(4))-1]+' '+ originaldata[value].month.slice(0,4)
                      }
                      catch(err){
                        console.log(err.message)
                      }
                      //return `€${Math.round(value)}`
                    }else{
                      //return `$${Math.round(value)}`
                      return null
                    }
                  },
          min : 0,
          max : 100,
          merge: 15,
          options:{
            margin:2
          }
          })

        const sliderUpdate = () => {
              chartOptions.value.series[0].data = originaldata.filter((item,index) => {
                  return index >= sliderOptions.value.value[0] && index <= sliderOptions.value.value[1]
              }).map(item => Number(item.value))

              chartOptions.value.xAxis.categories = months.filter((item,index) => {
                  return index >= sliderOptions.value.value[0] && index <= sliderOptions.value.value[1]
              })

              to_pass.value = originaldata.filter((item,index) => {
                  return index >= sliderOptions.value.value[0] && index <= sliderOptions.value.value[1]
              }).map(item => {
                return {'month':month_names[Number(item.month.slice(4))-1]+' '+ item.month.slice(0,4),'value':item.value}
              })
            }

        const chartOptions = ref({
                chart: {
                    type: 'line',
                    },
                title: {
                    text: 'Mortgage Origination',
                    },
                xAxis: {
                    type: 'category',
                    categories: [],
                    title: {
                        text: ''
                    }
                },
                yAxis:{
                    /*labels:{
                        formatter: function (){
                            return (this.value*100).toFixed(1) + '%';
                        }
                    },*/
                    title:{
                        text:''
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.x +
                            '</b>: <b>' + Math.round(this.y) + ' EUR million</b>';
                    }
                },
                series: [{
                    data: [], // sample data
                    marker: {
                        enabled :false
                    },
                    
                    }]
        })
        
        let count = 0
        watchEffect(() => {

            if (data.value.length > 0 && count == 0) {
                //console.log(data.value)
                count ++
                originaldata = data.value

                sliderOptions.value.max = data.value.length-1
                sliderOptions.value.value = [0,data.value.length-1]

                data.value.forEach( (item,index) => {   
                  chartOptions.value.series[0].data.push(Number(item.value))
                  months.push(month_names[Number(item.month.slice(4))-1]+' '+ item.month.slice(0,4))
                  to_pass.value.push({'month':months[index],'value': Number(item.value).toFixed(0)})
                  
                })
                chartOptions.value.xAxis.categories = months
            } 


        })
      
        let src = "https://www.bankofgreece.gr/RelatedDocuments/Rates_TABLE_1+1a.xls"
        let notes = "fsfsffsfs"

        return{data, error, chartOptions, sliderOptions, sliderUpdate, titles, to_pass, src, notes}
    },

}
</script>

<style scoped>
.sliderwrapper{
    padding-right:35px;
    padding-left:35px;
}
</style>
