import {ref} from 'vue'

const getData = () => {
    const data = ref([])
    const error = ref(null)

    const load = async(endpoint, params) => {
      try{

        let response = await fetch("https://api.interestingdata.eu/"+endpoint+"?"+new URLSearchParams(params))
        
        
        if (!response.ok){
          throw Error('Error when trying to fetch data')
        }
        data.value = await response.json()
        //console.log(data.value[1])
      }
      catch (err){
        error.value = err.message
      }
    }
    return{data, error, load}
}

export default getData