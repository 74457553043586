<template>
    <div class="row mt-5">
        <div class="col">
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">{{period}}</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dat in data" :key="dat.quarter">
                <th scope="row">{{dat.quarter}}</th>
                <td>{{dat.value}}</td>
              </tr>
            </tbody>
          </table>
        </div>       
    </div>
</template>

<script>
import {computed} from 'vue'

export default {
    name: "DataTable",
    props: ["data","error"],
    setup(props){
        //console.log(props)
        const period = computed(() => {
          if (props.data.length > 0){
            return Object.keys(props.data[0])[0].charAt(0).toUpperCase()+Object.keys(props.data[0])[0].slice(1)
          }else{
            return null
          }
        })

        const third_col = computed(() => {
          console.log(Object.keys(props.data[0]))
          if (Object.keys(props.data[0]).length === 3){
            return true
          }else{
            return false
          }
        }) 
        
    return {period,third_col}
    }

}
</script>

<style>

</style>