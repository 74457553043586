
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
//import Stock from 'highcharts/modules/stock'
//import Vue from 'vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@vueform/slider/themes/default.css'


//Stock(Highcharts);
const app = createApp(App)

app.use(router)
app.use(HighchartsVue)

app.mount('#app')

import 'bootstrap/dist/js/bootstrap.js'


