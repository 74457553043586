<template>

<div class="row" v-if="!error">
    <div class="row">
        <highcharts :options="chartOptions" ></highcharts>
    </div>
</div>
<div class="row" v-else>
    <div class="col">
        {{error}}
    </div>
</div>

</template>


<script>
import {ref} from 'vue'

export default {
    name: "BaseChart",
    props: ["chartOptions","error"],
    setup(props) {
        
    }
}

</script>

<style>

</style>