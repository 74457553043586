<template>
  <div class="home">
    <h1 class="mt-5">Hi there :)</h1>
    <div class="container">
      <div class="row mt-5">
        <div class="col">
          <div class="home-text xs col-md-6 offset-md-3">
              <div class="flex-container"><i class="bi bi-arrow-right-circle"></i><p>I plan to present data pertaining to the Greek environment, obtained from different sources</p></div>
              <div class="flex-container"><i class="bi bi-arrow-right-circle"></i><p>Going forward, I plan to incorporate more datasets, also from the EU as well as some with a global scope</p></div>
              <div class="flex-container"><i class="bi bi-arrow-right-circle"></i><p>All data presented are programmatically collected – and updated for that matter – from their respective sources by way of web scrapping, using Python Requests, BeautifulSoup and Pandas</p></div>
              <div class="flex-container"><i class="bi bi-arrow-right-circle"></i><p>Data is stored in a MySQL database; a Flask-RESTful API operates as an intermediary, facilitating DB operations and feeding the frontend with data</p></div>
              <div class="flex-container"><i class="bi bi-arrow-right-circle"></i><p>A crontab was set-up to undertake data updating, which runs daily on a VPS</p></div>
              <div class="flex-container"><i class="bi bi-arrow-right-circle"></i><p>This is a weekend project for me and I picked it up as a means to earn some hands-on experience on VueJS</p></div>
              <div class="flex-container"><i class="bi bi-arrow-right-circle"></i><p>Ping me with any queries at interestingdataeu[at]gmail.com</p></div>
              <div class="flex-container"><i class="bi bi-arrow-right-circle"></i><p>Thank you :)</p></div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {ref} from 'vue'


export default {
  name: 'HomeView',
  components: {},
  setup(){
 
  },
  methods:{
    //
  }
}
</script>

<style scoped>
.home-text {
  text-align:left;
}
.home-text p{
  margin:0px;
  font-size:18px;
}

i{
  font-size: 2rem;
  color: black;
  margin-right:12px;
}
.flex-container{
  display:flex;
  align-items: center;
  margin-bottom: 16px;
}
</style>
