import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UnplView from '../views/UnplView.vue'
import HpiView from '../views/HpiView.vue'
import GdpView from '../views/GdpView.vue'
import EnexView from '../views/EnexView.vue'
import MortgageOriginationView from '../views/MortgageOriginationView.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/hpi',
    name: 'Hpi',
    component: HpiView
  },
  {
    path: '/gdp',
    name: 'Gdp',
    component: GdpView
  },
  {
    path: '/unemployment',
    name: 'Unemployment',
    component: UnplView
  },
  {
    path: '/enex',
    name: 'Enex',
    component: EnexView
  },
  {
    path: '/mortgage-origination',
    name: 'Mortage Origination',
    component: MortgageOriginationView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
