<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light" >
  <a class="navbar-brand" href="/">Interesting <i class="bi bi-graph-up-arrow" style="font-weight: bold;"></i> Data</a>
  <button class="navbar-toggler" @click="toggleNavbar" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" :class="{ 'show': show }">
    <ul class="navbar-nav mr-auto">
      <li v-for="link in links" :key="link.name" class="nav-item">  
        <router-link :to="link.path" class="nav-link" @click="toggleNavbar">{{link.name}}</router-link>
      </li>
    </ul>
  </div>
</nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  props: ['links'],
  data(){
      return{
          show: false
      }
  },
  methods:{
    toggleNavbar() {
        this.show = !this.show;
    }
  }

}
</script>

<style>
  nav {
    padding: 30px;
    text-align: center;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: whitesmoke;
    background: crimson;
    border-radius: .5rem;
  }
</style>