<template>
<div class="container">
  <BaseChart :chartOptions="chartOptions" :error="error"/>
  
  <div class="row mt-5" v-if="data.length > 0">
  <div class="sliderwrapper"> 
    <Slider v-model="sliderOptions.value" 
      :format="sliderOptions.format" 
      :min = "sliderOptions.min"
      :max = "sliderOptions.max"
      :merge ="sliderOptions.merge"
      :options="sliderOptions.options"
      @update="sliderUpdate"  
      class = "mt-5" />
  </div>
  </div>
<!--<div class="mt-5">{{chartOptions}}</div>
  <div class="mt-5">{{sliderOptions}}</div>-->
  <DataTable :data="data" :error="error"/>
  <Accordion :src="src" :notes="notes" class = "mt-5"/>
</div>

</template>

<script>
import getData from '../composables/getData'
//import LineChart from '@/components/LineChart.vue'
import DataTable from '@/components/DataTable.vue'
import Slider from '@vueform/slider'
import {ref,watchEffect} from 'vue'
import BaseChart from '../components/BaseChart.vue'
import Accordion from '@/components/Accordion.vue'

export default {
    name: 'HpiView',
    props:[],
    components: { DataTable, Slider, BaseChart,Accordion },
    setup(){
        //console.log($router)
        let originaldata = []
        const {data, error, load} = getData('hpi')
        load()

        const sliderOptions = ref({
          value: [20,40],
          format: function (value) {
                    if(data.value.length > 0){
                      try{
                        return originaldata[value].quarter
                      }
                      catch(err){
                        console.log(err.message)
                      }
                      //return `€${Math.round(value)}`
                    }else{
                      //return `$${Math.round(value)}`
                      return null
                    }
                  },
          min : 0,
          max : 80,
          merge:2,
          options:{
            margin:2
          }
          })


        const sliderUpdate = () => {
          data.value = originaldata.slice(sliderOptions.value.value[0],sliderOptions.value.value[1]+1)
        }

         

        const chartOptions = ref({
                chart: {
                    type: 'line'
                    },
                title: {
                    text: 'Greek HPI',
                    },
                xAxis: {
                    type: 'category',
                    title: {
                        text: 'Quarters'
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    valueDecimals: 2
                },
                series: [{
                    data: [], // sample data
                    marker: {
                        enabled :false
                    },
                    name: "Greek HPI"
                    }]
        })
        let count = 0 
        watchEffect(() => {
          if (data.value.length > 0 && count == 0) {
            console.log('watcheffect')          
            sliderOptions.value.max = data.value.length-1
            sliderOptions.value.value = [0,data.value.length-1]
            data.value.forEach( (item,index) => {
              data.value[index].value = (Math.round(Number(item.value) * 100) / 100).toFixed(2)
              chartOptions.value.series[0].data.push([item.quarter, Number(item.value)])
            })
            originaldata = data.value
            count++
          }else{
            /*data.value.forEach( (item) => {
              chartOptions.value.series[0].data.push([item.quarter, Number(item.value)])
            })*/
            chartOptions.value.series[0].data = data.value.map((item) => {
              return [item.quarter,Number(item.value)]
            })

          }
        })

        let src = "https://www.bankofgreece.gr/RelatedDocuments/TE_PRICES_INDICES_HISTORICAL_SERIES.xls"
        let notes = "mpalsda"

        
        return{data,error,chartOptions,sliderUpdate, sliderOptions,src, notes}
    },

}
</script>

<style scoped>
.sliderwrapper{
    padding-right:30px;
    padding-left:30px;
}
</style>

