<template>
<div class="container">
  <BaseChart :chartOptions="chartOptions" :error="error" />
  <div class="row mt-5" v-if="data.length > 0">
  <div class="sliderwrapper">
    <Slider v-model="sliderOptions.value" 
      :format="sliderOptions.format" 
      :min = "sliderOptions.min"
      :max = "sliderOptions.max"
      :merge ="sliderOptions.merge"
      :options="sliderOptions.options"
      @update="sliderUpdate"  
      class = "mt-5" />
    </div>
</div>
<div class="row mt-5" v-if="data.length > 0"> 
  <DataTable :data="to_pass" :titles="titles" />
</div>

<div class="row mt-5"> 
  <Accordion :src="src" :notes="notes" class = "mt-5"/>
</div>
  
  </div>
</template>

<script>
import getData from '../composables/getDataParams'
import BaseChart from '@/components/BaseChart.vue'
import DataTable from '@/components/DataTable2.vue'
import {ref, watchEffect} from 'vue'
import Slider from '@vueform/slider'
import Accordion from '@/components/Accordion.vue'

export default {
    name: 'GdpView',
    components: {BaseChart, DataTable, Slider, Accordion},
    setup(){

        const {data, error, load} = getData()
        load('gdp')

        const annual = ref([])
        const qgrowth = ref([''])
        const ygrowth = ref(['','','',''])
        const agrowth = ref([])

        const to_pass = ref([])

        const titles = ref(['q-o-q','y-o-y'])
        const years = ref([])

        const sliderOptions = ref({
          value: [0,25],
          format: function (value) {
                    if(data.value.length > 0){
                      try{
                        return years.value[value]
                      }
                      catch(err){
                        console.log(err.message)
                      }
                      //return `€${Math.round(value)}`
                    }else{
                      //return `$${Math.round(value)}`
                      return null
                    }
                  },
          min : 0,
          max : 25,
          merge:2,
          options:{
            margin:2
          }
          })

        const sliderUpdate = () => {
                chartOptions.value.series[0].data = agrowth.value.filter((item,index) => {
                    return index >= sliderOptions.value.value[0] && index <= sliderOptions.value.value[1]
                })
                chartOptions.value.xAxis.categories = years.value.filter((item,index) => {
                    return index >= sliderOptions.value.value[0] && index <= sliderOptions.value.value[1]
                })

                //console.log(to_pass.value)
                //console.log(sliderOptions.value.value)
              }

        const chartOptions = ref({
                chart: {
                    type: 'column',
                    },
                title: {
                    text: 'Annual GDP Growth',
                    },
                xAxis: {
                    type: 'category',
                    categories: [],
                    title: {
                        text: ''
                    }
                },
                yAxis:{
                    labels:{
                        formatter: function (){
                            return this.value*100 + '%';
                        }
                    },
                    title:{
                        text:''
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.x +
                            '</b>: <b>' + Math.round(this.y*100*100)/100 + '%</b>';
                    }
                },
                series: [{
                    data: [], // sample data
                    marker: {
                        enabled :false
                    },
                    //name: "GDP Growth"
                    }]
        })
        
        let count = 0
        watchEffect(() => {

            if (data.value.length > 0 && count == 0) {

                count ++

                years.value = Array.from({length: Math.floor(data.value.length/4)-1}, (_, i) => i + 1996)
                chartOptions.value.xAxis.categories = years.value

                sliderOptions.value.max = years.value.length-1
                sliderOptions.value.value[1] = sliderOptions.value.max

                let s = 0
                data.value.forEach( (item,index) => {                   
                    try{
                        qgrowth.value.push((Number(item.value) - Number(data.value[index-1].value))/Number(data.value[index-1].value))
                    }catch(err){
                        console.log(err)
                    }
                    if (index > 3){                       
                        ygrowth.value.push((Number(item.value) - Number(data.value[index-4].value))/Number(data.value[index-4].value))
                    }

                    s+= Number(item.value)

                    if (index%4 == 3){
                        annual.value.push(s) //sto pinaka annual exoume gia ka8e etos, to a8roisma tou gdp
                        if (annual.value.length > 1){
                            agrowth.value.push((s-annual.value[annual.value.length-2])/annual.value[annual.value.length-2])
                        }
                        s=0
                    }
                    to_pass.value.push({'quarter':item.quarter, 
                    'value':(qgrowth.value[index]).toLocaleString("en", {style: "percent",minimumFractionDigits: 2}),
                    'value2':(ygrowth.value[index]).toLocaleString("en", {style: "percent",minimumFractionDigits: 2})
                    })
                    
                })

                chartOptions.value.series[0].data = agrowth.value

                /*data.value.forEach( (item,index) => {
                    to_pass.value.push({'quarter':item.quarter, 
                    'value':(qgrowth.value[index]).toLocaleString("en", {style: "percent",minimumFractionDigits: 2}),
                    'value2':(ygrowth.value[index]).toLocaleString("en", {style: "percent",minimumFractionDigits: 2})
                    })
                })*/

                
            }   

        })
      
        let src = "https://www.statistics.gr/en/statistics/-/publication/SEL84/-"
        let notes = "Quarterly GDP - Seasonally adjusted - Chain-linked volumes (reference year 2015)"

        return{data, error, chartOptions, sliderOptions, sliderUpdate, to_pass, titles, src, notes}
    },

}
</script>

<style scoped>
.sliderwrapper{
    padding-right:20px;
    padding-left:20px;
}
</style>
