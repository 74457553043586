  <template>
  <NavigationBar :links="links"/>
  <router-view/>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
import {computed} from 'vue'
import {useRouter} from 'vue-router'

export default {
  components:{
    NavigationBar
  },
  setup() {
    const routes = useRouter().getRoutes()

    const links = computed(() => {
      return routes.map( (link) => {
        let b = {"path": link.path, "name":link.name}
        return b
      })
    })
        
    return {links}
  }  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.highcharts-credits{
  display:none;
}


</style>
