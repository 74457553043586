<template>
  <div class="container">
    <div class="chart-wrapper mt-5">
        <BaseChart :chartOptions="chartOptions" :error="error" />
    </div>
    <div class="datepicker-wrapper mt-5 col-md-4 offset-md-4">
        <Datepicker v-model="date" 
                :enableTimePicker="false" 
                :minDate="minDate" 
                :maxDate="maxDate" 
                :monthNameFormat="`long`"
                :format = "format"
                 @update:modelValue="newDateSelected" />
    </div>
    <div class="mt-5 pie-wrapper">
        <BaseChart :chartOptions="pieChartOptions" :error="error" />
    </div>
                 
  </div>
</template>


<script>
import BaseChart from '@/components/BaseChart.vue'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import getData from '../composables/getDataParams.js'
import {ref,watchEffect} from 'vue'


export default {
    name: 'EnexView',
    components: {BaseChart, Datepicker},
    setup(){

        //const date = ref(new Date().toISOString().slice(0, 10))
        const date = ref(new Date().toISOString().slice(0, 10))
        const minDate = "2020-11-01"
        const maxDate = ref()
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const {data, error, load} = getData()       
        load('enex')



        const chartOptions = ref({
                chart: {
                    //type: 'column',
                    //zoomType: 'xy',
                    alignTicks: false
                    },
                title: {
                    text: '',
                },
                xAxis: {
                    categories: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
                    crosshair: true,
                    title: {
                        enabled: false
                    }
                },
                yAxis:
                [{title:{text:''}},
                { // Primary yAxis
                    labels: {
                        formatter: function (){
                            return (this.value/1000) + 'K';
                        },
                        style: {
                            color: 'gray'
                        }
                    },
                    title: {
                        text: 'Volume (MWh)',
                        style: {
                            color: 'gray'
                        }
                    },
                }, { // Secondary yAxis
                    gridLineWidth: 0,
                    softMin: 0,
                    floor: 0,
                    tickInterval: 50,
                    title: {
                        text: 'Market Clearing Price (€/MWh)',
                        style: {
                            color: 'gray'
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: 'gray'
                        }
                    },
                    opposite: true
                },
                ],
                legend: {
                    enabled: true,
                    reversed: true
                },
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: ' MWh'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        },
                    },                    
                },
                series: [
                    {
                    data: [], // 0
                    marker: {
                        enabled :false
                        },  
                    name: "Pump",
                    type: 'column',
                    yAxis: 1,
                    color: '#7CB5EC'
                    },
                    {
                    data: [], // 1
                    marker: {
                        enabled :false
                        },  
                    name: "Exports",
                    type: 'column',
                    yAxis: 1,
                    color: '#F7A35C'
                    },
                    {
                    data: [], // 2
                    marker: {
                        enabled :false
                        },  
                    name: "RES",
                    type: 'column',
                    yAxis: 1,
                    color: '#90ED7D'
                    },
                    {
                    data: [], // 3
                    marker: {
                        enabled :false
                        },  
                    name: "Crete RES",
                    type: 'column',
                    yAxis: 1,
                    color: 'green'
                    },
                    {
                    data: [], // 4
                    marker: {
                        enabled :false
                        },  
                    name: "Imports",
                    type: 'column',
                    yAxis: 1,
                    color: '#c33e23',
                    },
                    {
                    data: [], // 5
                    marker: {
                        enabled :false
                        },  
                    name: "Big Hydro",
                    type: 'column',
                    yAxis: 1,
                    color: 'deepskyblue'
                    },
                    {
                    data: [], // 6
                    marker: {
                        enabled :false
                        },  
                    name: "Natural Gas",
                    type: 'column',
                    yAxis: 1,
                    color: '#E4D354'
                    },
                    {
                    data: [], // 7
                    marker: {
                        enabled :false
                        },  
                    name: "Crete Conventional",
                    type: 'column',
                    yAxis: 1,
                    color:'rosybrown'
                    },
                    {
                    data: [], // 8
                    marker: {
                        enabled :false
                        },  
                    name: "Lignite",
                    type: 'column',
                    yAxis: 1,
                    color: 'saddlebrown'
                    },
                    {
                    data: [], // 9
                    marker: {
                        enabled :true,
                        fillColor: '#646464'
                        },  
                    name: "MCP",
                    type: 'spline',
                    dashStyle: 'shortdot',
                    linewidth: 1,
                    yAxis: 2,
                    color: 'darkgrey',
                    tooltip: {
                            valueDecimals: 2,
                            valuePrefix: '€',
                            valueSuffix: '/MWh'
                        },
                    },
                    ],
                responsive:{
                    rules: [{
                        condition:{
                            maxWidth:500
                        },
                        chartOptions:{
                            yAxis:[{
                                title: {
                                    text:''
                                }},
                                {//PRIMARY
                                    title: {
                                        text:''
                                    },
                                    /*labels: {
                                        enabled: false
                                    }*/
                                },
                                {//Secondary
                                    title: {
                                        text:''
                                    },
                                    labels: {
                                        enabled: false
                                    }
                                },
                            ]
                        }
                    }]
                }
        })

        const pieChartOptions = ref({
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                    },
                title: {
                    text: 'Daily Energy Mix'
                },
                tooltip: {
                    pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>',
                    headerFormat: null
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            distance: '-30%'
                        },
                        showInLegend: false
                    }
                },
                series: [{
                    colorByPoint: true,
                    data: [{
                        name: 'Renewables',
                        y: 50,
                        /*
                        sliced: true,
                        selected: true,
                        */
                        color: '#90ED7D'
                    }, {
                        name: 'Fossil Fuel',
                        y: 50,
                        color: 'saddlebrown'
                    }, 
                    ]
                }]
        })

        const newDateSelected = (modelData) => {
            date.value = new Date(modelData).toISOString().slice(0, 10)
            load('enex',{'day':date.value})
            }

        const format = (date) => {
            const day = date.getDate()
            const month = months[date.getMonth()] 
            const year = date.getFullYear()

            return `${day} ${month} ${year}`
        }

        watchEffect(() => {
            //console.log('watch effect')
            //console.log(data.value)
    
            if (data.value.lignite) {

                if (!maxDate.value) {
                    console.log('setting max date')
                    date.value = new Date(data.value.day).toISOString().slice(0, 10)
                    maxDate.value = new Date(data.value.day).toISOString().slice(0, 10)
                }
                console.log(date.value)
                chartOptions.value.title.text = "Day Ahead Market of "+date.value.slice(8,10)+" "+months[Number(date.value.slice(5,7))-1]+" "+date.value.slice(0,4)

                let al_gr_keys = Object.keys(JSON.parse(data.value.al_gr))
                let bg_gr_keys = Object.keys(JSON.parse(data.value.bg_gr))
                let cr_gr_keys = Object.keys(JSON.parse(data.value.cr_gr))
                let it_gr_keys = Object.keys(JSON.parse(data.value.it_gr))
                let mk_gr_keys = Object.keys(JSON.parse(data.value.mk_gr))
                let tr_gr_keys = Object.keys(JSON.parse(data.value.tr_gr))
                let gr_al_keys = Object.keys(JSON.parse(data.value.gr_al))
                let gr_bg_keys = Object.keys(JSON.parse(data.value.gr_bg))
                let gr_cr_keys = Object.keys(JSON.parse(data.value.gr_cr))
                let gr_it_keys = Object.keys(JSON.parse(data.value.gr_it))
                let gr_mk_keys = Object.keys(JSON.parse(data.value.gr_mk))
                let gr_tr_keys = Object.keys(JSON.parse(data.value.gr_tr))

                let imports = new Array(24).fill(0)
                let exports = new Array(24).fill(0)
                
                let lignite_keys = Object.keys(JSON.parse(data.value.lignite))
                let natural_gas_keys = Object.keys(JSON.parse(data.value.natural_gas))
                let crete_conventional_keys = Object.keys(JSON.parse(data.value.crete_conventional))
                let crete_renewables_keys = Object.keys(JSON.parse(data.value.crete_renewables))
                let res_keys = Object.keys(JSON.parse(data.value.res))
                let big_hydro_keys = Object.keys(JSON.parse(data.value.big_hydro))

                let pump_keys = Object.keys(JSON.parse(data.value.pump))
                
                let lignite = new Array(24).fill(0)
                let natural_gas = new Array(24).fill(0)
                let crete_conventional = new Array(24).fill(0)
                let crete_renewables = new Array(24).fill(0)
                let res = new Array(24).fill(0)
                let big_hydro = new Array(24).fill(0)

                let pump = new Array(24).fill(0)

                let mcp = new Array(24).fill(0)
                //console.log(cr_gr_keys)
                for (let i = 1; i < 25; i++) {
                    if (al_gr_keys.includes(i.toString()+'.0')) {
                        imports[i-1] += JSON.parse(data.value.al_gr)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (bg_gr_keys.includes(i.toString()+'.0')) {
                        imports[i-1] += JSON.parse(data.value.bg_gr)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (cr_gr_keys.includes(i.toString()+'.0')) {
                        imports[i-1] += JSON.parse(data.value.cr_gr)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (it_gr_keys.includes(i.toString()+'.0')) {
                        imports[i-1] += JSON.parse(data.value.it_gr)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (mk_gr_keys.includes(i.toString()+'.0')) {
                        imports[i-1] += JSON.parse(data.value.mk_gr)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (tr_gr_keys.includes(i.toString()+'.0')) {
                        imports[i-1] += JSON.parse(data.value.tr_gr)[i.toString()+'.0'].TOTAL_TRADES
                    }
                    if (gr_al_keys.includes(i.toString()+'.0')) {
                        exports[i-1] -= JSON.parse(data.value.gr_al)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (gr_bg_keys.includes(i.toString()+'.0')) {
                        exports[i-1] -= JSON.parse(data.value.gr_bg)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (gr_cr_keys.includes(i.toString()+'.0')) {
                        exports[i-1] -= JSON.parse(data.value.gr_cr)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (gr_it_keys.includes(i.toString()+'.0')) {
                        exports[i-1] -= JSON.parse(data.value.gr_it)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (gr_mk_keys.includes(i.toString()+'.0')) {
                        exports[i-1] -= JSON.parse(data.value.gr_mk)[i.toString()+'.0'].TOTAL_TRADES
                    }
					if (gr_tr_keys.includes(i.toString()+'.0')) {
                        exports[i-1] -= JSON.parse(data.value.gr_tr)[i.toString()+'.0'].TOTAL_TRADES
                    }
                    if (lignite_keys.includes(i.toString()+'.0')) {
                        lignite[i-1] = JSON.parse(data.value.lignite)[i.toString()+'.0'].TOTAL_TRADES
                    }
                    if (natural_gas_keys.includes(i.toString()+'.0')) {
                        natural_gas[i-1] = JSON.parse(data.value.natural_gas)[i.toString()+'.0'].TOTAL_TRADES
                    }
                    if (crete_conventional_keys.includes(i.toString()+'.0')) {
                        crete_conventional[i-1] = JSON.parse(data.value.crete_conventional)[i.toString()+'.0'].TOTAL_TRADES
                    }
                    if (crete_renewables_keys.includes(i.toString()+'.0')) {
                        crete_renewables[i-1] = JSON.parse(data.value.crete_renewables)[i.toString()+'.0'].TOTAL_TRADES
                    }
                    if (big_hydro_keys.includes(i.toString()+'.0')) {
                        big_hydro[i-1] = JSON.parse(data.value.big_hydro)[i.toString()+'.0'].TOTAL_TRADES
                    }
                    if (res_keys.includes(i.toString()+'.0')) {
                        res[i-1] = JSON.parse(data.value.res)[i.toString()+'.0'].TOTAL_TRADES
                    }
                    if (pump_keys.includes(i.toString()+'.0')) {
                        pump[i-1] = -JSON.parse(data.value.pump)[i.toString()+'.0'].TOTAL_TRADES
                    }

                    mcp[i-1] = JSON.parse(data.value.res)[i.toString()+'.0'].MCP
                }
                chartOptions.value.series[4].data = imports
                chartOptions.value.series[1].data = exports
                chartOptions.value.series[8].data = lignite
                chartOptions.value.series[6].data = natural_gas
                chartOptions.value.series[7].data = crete_conventional
                chartOptions.value.series[3].data = crete_renewables
                chartOptions.value.series[2].data = res
                chartOptions.value.series[5].data = big_hydro
                chartOptions.value.series[0].data = pump

                chartOptions.value.series[9].data = mcp

                let fossil = lignite.reduce((partialSum, a) => partialSum + a, 0)
                fossil += natural_gas.reduce((partialSum, a) => partialSum + a, 0)
                fossil += crete_conventional.reduce((partialSum, a) => partialSum + a, 0)
                
                let green = res.reduce((partialSum, a) => partialSum + a, 0)
                green += big_hydro.reduce((partialSum, a) => partialSum + a, 0)
                green += crete_renewables.reduce((partialSum, a) => partialSum + a, 0)
                console.log(green)
                console.log(pieChartOptions.value.series[0].data)
                pieChartOptions.value.series[0].data[0] = (green)/(fossil+green)
                pieChartOptions.value.series[0].data[1] = (fossil)/(fossil+green)
            }

        })

        return {chartOptions, error, date, minDate, maxDate, format, newDateSelected, pieChartOptions}
    }
}
</script>

<style scoped>

</style>