<template>
    <div class="row mt-5">
        <div class="col">
          <!-- Has two columns-->
          <table class="table table-sm table-striped" v-if="hasThirdCol">
            <thead>
              <tr>
                <th scope="col">{{period_name}}</th>
                <th scope="col">{{titles[0]}}</th>
                <th scope="col">{{titles[1]}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dat in local_data" :key="dat.period">
                <th scope="row">{{dat.period}}</th>
                <td>{{dat.value}}</td>
                <td>{{dat.value2}}</td>
              </tr>
            </tbody>
          </table>
          <!-- Has two columns-->
          <table class="table table-sm table-striped" v-else> 
            <thead>
              <tr>
                <th scope="col">{{period_name}}</th>
                <th scope="col">{{titles[0]}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dat in local_data" :key="dat.period">
                <th scope="row">{{dat.period}}</th>
                <td>{{dat.value}}</td>
              </tr>
            </tbody>
          </table>
        </div>       
    </div>
</template>

<script>
import {computed} from 'vue'

export default {
    name: "DataTable",
    props: ["data","titles"],
    setup(props){
        console.log(props)

        const hasThirdCol = computed(() => {
          if (props.data.length > 0){
            //console.log(Object.keys(props.data[0]))
            if (Object.keys(props.data[0]).length === 3){
              return true
            }else{
              return false
            }
          }
          return false
        })
        
        const local_data = computed(() => {
          if (props.data.length > 0){
            let key1 = Object.keys(props.data[0])[0]
            return props.data.map(item => {
              //console.log(item)
              return {'period':item[key1], 'value':item.value, 'value2':hasThirdCol?item.value2:''}
            })
          }else{
            return null
          }
        }) 
        
        const period_name = computed(() => {
          if (props.data.length > 0){
            return Object.keys(props.data[0])[0].charAt(0).toUpperCase()+Object.keys(props.data[0])[0].slice(1)
          }else{
            return null
          }
        })

        

        
    return {period_name,hasThirdCol, local_data}
    }

}
</script>

<style>

</style>